import React from 'react';
import HeadTags from '../components/HeadTags';

//! Components
import Layout from '../components/layout';
import CTA from '../components/CTA';
import PrivacyPolicy from '../components/PrivacyPolicy';

//! CSS
import '../styles/style.css';
import '../styles/new.css';
import '../styles/revamp.css';

const IndexPage = () => (
  <Layout>
     <HeadTags
      title={`ChatBots | Customer Service Conversational AI Chatbots `}
      content={{ type: 'article', url: 'legal' }}
      content={{
        type: 'website',
        description:
          'Automate your customer support and engagement with Conversational AI chatbots, used by leading banks, insurance companies and fintechs globally. No technical skills required.'
      }}
    />
    <PrivacyPolicy />
    <CTA />
  </Layout>
);

export default IndexPage;
