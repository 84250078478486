import React from 'react';

const PrivacyPolicy = props => {
  return (
    <main className="legal-page" data-display="privacy-policy">
      <ul className="legal-navigation">
        <li className="legal-display" data-display="privacy-policy">
          <a href="#privacy-policy">Privacy Policy</a>
        </li>
        <li className="legal-display" data-display="terms-of-service">
          <a href="#terms-of-service">Terms of Service</a>
        </li>
        <li className="legal-display" data-display="acceptable-use-policy">
          <a href="#acceptable-use-policy">Acceptable Use Policy</a>
        </li>
        <li
          className="legal-display"
          data-display="support-and-service-level-agreement"
        >
          <a href="#support-and-service-level-agreement">
            Support and Service Level Agreement
          </a>
        </li>
      </ul>
      <div className="tab-folder">
        <section
          className="legal-section terms-of-service"
          id="terms-of-service"
        >
          <h2>TERMS OF SERVICE</h2>
          <p>
            These Terms of Service (these
            <b>“Terms”</b>) contains the terms and
            <br /> conditions that govern your access to and use of the
            services, software and
            <br /> websites (<b>“Services”</b>) provided by Active Intelligence
            Pte Ltd
            <br /> (<b>“us”</b>,<b>“we”</b>, or
            <b>“our”</b>). By clicking ‘accept,’ or using
            <br /> any of the Services, you represent to us that you are
            lawfully able to enter
            <br /> into contracts (e.g., you are not a minor). If you are
            accepting these Terms
            <br /> on behalf of an entity, such as the company you work for, you
            represent to
            <br /> us that you have legal authority to bind that entity and
            agree to these
            <br /> Terms. If you do not have authority to accept these Terms, or
            if you do not
            <br /> agree with its terms, do not click ‘accept’ and do not use
            the Services.
          </p>
          <h4>1. APPLICATION OF THESE TERMS</h4>
          <div className="legal-page-margin">
            <p>
              1.1 These Terms govern our Services, and all related technical and
              other
              <br /> support services that we or our affiliates may provide to
              you in
              <br /> relation to the Services (<b>“Support”</b>).
            </p>
            <p>
              1.2 These Terms include our Acceptable Use Policy, Privacy Policy
              and
              <br /> Support Policy (collectively, the
              <b>“Policies”</b>).
            </p>
            <p>
              1.3 These Terms do not apply to our downloadable software
              products, the
              <br /> use of which requires a separate licence with us.
            </p>
            <p>
              1.4 We may change or discontinue any or all of the Services or
              change or
              <br /> remove functionality of any or all of the Services from
              time to time. We
              <br /> will notify you of any material change to or
              discontinuation of the
              <br /> Services via our Website. In case of any discontinuation of
              any of the
              <br /> Services, we will endeavour to provide you with 3 months’
              notice prior
              <br /> to such discontinuation.
            </p>
          </div>
          <h4>2. YOUR USE OF THE SERVICES</h4>
          <div className="legal-page-margin">
            <p>
              2.1 Through the Services, you may be able to specify certain
              personnel
              <br /> as administrators who will administer the Services to other
              users on
              <br /> your behalf (<b>“Administrators”</b>). Administrators will
              have
              <br /> important rights and controls over your use of the
              Services. Without
              <br /> limiting Clause 2.2 and 2.2.4, which fully applies to
              Administrators,
              <br /> you are responsible for whom you allow to become
              Administrators and any
              <br /> actions they take, including as described above. You agree
              that our
              <br /> responsibilities do not extend to the internal management
              or
              <br /> administration of the Services for you.
            </p>
            <p>2.2 In using these Services, you are responsible for:</p>
            <div className="legal-page-margin">
              <p>
                2.2.1 any action that you permit, assist or facilitate any
                person or
                <br /> entity to take related to these Terms, your content or
                use of the
                <br /> Services;
              </p>
              <p>
                2.2.2 understanding the settings and controls for the Services
                you
                <br /> use;
              </p>
              <p>
                2.2.3 all activities that occur under your account, regardless
                of
                <br /> whether the activities are authorised by you or
                undertaken by you,
                <br /> your employees or a third party (including your
                contractors, agents,
                <br /> or any individual or entity that directly or indirectly
                interacts
                <br /> with our Services through you (<b>“End Users”</b>)). We
                and our
                <br /> affiliates are not responsible for unauthorised access to
                your
                <br /> account;
              </p>
              <p>2.2.4 your End Users’ use of your content and the Services;</p>
              <p>
                2.2.5 compliance with these Terms by all End Users (even if
                those
                <br /> End Users are not from your organisation or domain), and
                for
                <br /> ensuring that the terms of your agreement with each End
                User are
                <br /> consistent with these Terms;
              </p>
              <p>
                2.2.6 displaying these Terms to End Users, or directing End
                Users to
                <br /> these Terms, and for any damages resulting from your
                failure to do
                <br /> so;
              </p>
              <p>
                2.2.7 properly configuring and using the Services and otherwise
                <br /> taking appropriate action to secure, protect and back up
                your
                <br /> accounts and your content in a manner that will provide
                appropriate
                <br /> security and protection, which might include use of
                encryption to
                <br /> protect your content from unauthorised access and
                routinely
                <br /> archiving your content;
              </p>
              <p>
                2.2.8 the development, content, operation, maintenance, and use
                of
                <br /> your content that is to be displayed on or used in
                connection with
                <br /> the Services; and
              </p>
              <p>
                2.2.9 ensuring that your content and your and End Users’ use of
                your
                <br /> content and/or the Services will not violate these Terms
                or any
                <br /> applicable law.
              </p>
            </div>
            <p>
              2.3 If you become aware of any violation of your obligations under
              these
              <br /> Terms caused by an End User, you will immediately suspend
              access to your
              <br /> content and the Services by such End User. We do not
              provide any support
              <br /> or services to End Users unless we have a separate
              agreement with you or
              <br /> an End User obligating us to provide such support or
              service.
            </p>
            <p>
              2.4 Your log-in credentials and private keys generated by the
              Services
              <br /> are for your internal use only and you will not sell,
              transfer or
              <br /> sublicense them to any other entity or person, except that
              you may
              <br /> disclose your private key to your agents and subcontractors
              performing
              <br /> work on your behalf.
            </p>
            <p>
              2.5 Where you are required to specify a domain for the operation
              of a
              <br /> Service, we may verify that you own or control that domain.
              If you do
              <br /> not own or control the domain you specify, then we will
              have no
              <br /> obligation to provide you with the Services.
            </p>
            <p>
              2.6 During the term of your subscription to the Services, we may
              provide
              <br /> Support for the Services in accordance with the Support
              Policy.
            </p>
            <p>2.7 By accessing the Services, you agree that:</p>
            <p>
              2.7.1 you will adhere to the Policies, which are hereby
              incorporated
              <br /> into these Terms;
            </p>
            <p>
              2.7.2 you will notify us promptly if you become aware of any End
              User’s
              <br /> violation of these Terms;
            </p>
            <p>2.7.3 you will not impersonate any person or entity;</p>
            <p>
              2.7.4 you will not express or imply that any statements you make
              are
              <br /> endorsed by us without specific prior written consent;
            </p>
            <p>
              2.7.5 you will not remove any copyright, trademark or other
              proprietary
              <br /> rights notices displayed through the Services;
            </p>
            <p>
              2.7.6 you will not post, distribute or reproduce in any way any
              <br /> copyrighted material, trademarks, or other proprietary
              information
              <br /> without obtaining the prior consent of the owner of such
              proprietary
              <br /> rights; and
            </p>
            <p>
              2.7.7 you will not directly modify, adapt, translate, reverse
              engineer,
              <br /> decipher, decompile or otherwise disassemble any portion of
              the Services
              <br /> or any software used on or for the Services, or cause or
              assist others
              <br /> to do so, except with our specific prior written consent.
            </p>
          </div>
          <h4>3. PAYMENT OBLIGATIONS</h4>
          <div className="legal-page-margin">
            <p>
              3.1
              <b>Service Fees</b>
            </p>
            <div className="legal-page-margin">
              <p>
                3.1.1 We calculate and bill fees and charges monthly/yearly. We
                may
                <br /> bill you more frequently for fees accrued if we suspect
                that your
                <br /> account is fraudulent or at risk of non-payment. You will
                pay us the
                <br /> applicable fees and charges for use of the Services using
                one of the
                <br /> payment methods we support.
              </p>
              <p>
                3.1.2 Other than as expressly stated in these Terms, all amounts
                <br /> payable to us are non-refundable, non-cancellable,
                non-creditable,
                <br /> and without setoff or counterclaim, and without any
                deduction or
                <br /> withholding.
              </p>
              <p>
                3.1.3 We may increase or add new fees and charges for any
                existing
                <br /> Services you are using by giving you at least 30 days
                prior notice.
                <br /> We may elect to charge you interest at the rate of 1.5%
                per month
                <br /> (or the highest rate permitted by law, if less) on all
                late
                <br /> payments.
              </p>
            </div>
            <p>
              <b>3.2 Taxes</b>
            </p>
            <div className="legal-page-margin">
              <p>
                3.2.1 You will be responsible, as required under applicable
                laws,
                <br /> for identifying and paying all taxes and other
                governmental fees and
                <br /> charges (and any penalties, interest, and other additions
                thereto)
                <br /> that are imposed on you upon or with respect to the
                transactions and
                <br /> payments under these Terms.
              </p>
              <p>
                3.2.2 All fees payable by you are exclusive of applicable taxes
                and
                <br /> duties, including, without limitation, goods and services
                taxes,
                <br /> excise taxes, sales and transactions taxes, and gross
                receipts tax
                <br /> (“Indirect Taxes”). We may charge and you will pay
                applicable
                <br /> Indirect Taxes that we are legally obligated or
                authorised to
                <br /> collect from you. You will provide such information to us
                as
                <br /> reasonably required to determine whether we are obligated
                to collect
                <br /> Indirect Taxes from you.
              </p>
              <p>
                3.2.3 Notwithstanding the foregoing, if you have obtained an
                <br /> exemption from relevant taxes or duties as of the time
                such taxes or
                <br /> duties are levied or assessed, you may provide us with
                such
                <br /> exemption information.
              </p>
              <p>
                3.2.4 All payments made by you to us under these Terms will be
                made
                <br /> free and clear of any deduction or withholding, as may be
                required
                <br /> by law. If any such deduction or withholding (including
                but not
                <br /> limited to cross-border withholding taxes) is required on
                any
                <br /> payment, you will pay such additional amounts as are
                necessary so
                <br /> that the net amount received by us is equal to the amount
                then due
                <br /> and payable under these Terms. We will provide you with
                such tax
                <br /> forms as are reasonably requested in order to reduce or
                eliminate
                <br /> the amount of any withholding or deduction for taxes in
                respect of
                <br /> payments made under these Terms.
              </p>
            </div>
          </div>
          <h4>4. EVALUATIONS, TRIALS AND BETAS</h4>
          <div className="legal-page-margin">
            <p>
              4.1 We may offer certain Services or certain features thereof to
              you at
              <br /> no charge, including free accounts, trial use, pre-release
              and beta
              <br /> Services or features of Services (“Beta Versions)
              (collectively,
              <br /> “No-Charge Services”). Your use of No-Charge Services is
              subject to this
              <br /> Clause 4, and is only permitted during the term that we
              designate (or,
              <br /> if not designated, until terminated in accordance with
              these Terms).
              <br /> Except as otherwise set forth in this Clause 4, these Terms
              shall fully
              <br /> apply to your access to and/or use of No-Charge Services.
              We may modify
              <br /> or terminate your right to use No-Charge Services at any
              time and for
              <br /> any reason in our sole discretion, without liability to
              you.
            </p>
            <p>
              4.2 You understand that any Beta Versions are still under
              development,
              <br /> may be inoperable or incomplete and are likely to contain
              more errors
              <br /> and bugs than generally available Services. We make no
              promises that any
              <br /> Beta Versions will ever be made generally available. In
              some
              <br /> circumstances, we may charge a fee in order to allow you to
              access Beta
              <br /> Versions, but the Beta Versions will still remain subject
              to this Clause
              <br /> 4.
            </p>
            <p>
              4.3 All information regarding the characteristics, features or
              <br /> performance of any No-Charge Services (including Beta
              Versions)
              <br /> constitutes our Confidential Information, and is subject to
              Clause 8.
            </p>
            <p>
              4.4 To the maximum extent permitted by applicable law, we disclaim
              all
              <br /> obligations or liabilities with respect to No-Charge
              Services, including
              <br /> any Support, warranty and indemnity obligations.
              Notwithstanding
              <br /> anything else in these Terms, our maximum aggregate
              liability to you in
              <br /> respect of No-Charge Services will be S$100.
            </p>
          </div>
          <h4>5. BRANDING AND ATTRIBUTION</h4>
          <div className="legal-page-margin">
            <p>
              5.1 You agree to display any attribution(s) required by us as
              described
              <br /> in any documentation for the Services. We grant to you a
              limited, freely
              <br /> revocable, non transferable, non sublicenseable,
              nonexclusive license
              <br /> during the term of your subscription to display Our Marks
              solely for the
              <br /> purpose of promoting or advertising that your use the
              Services. You must
              <br /> only use Our Marks in accordance with these Terms. You
              understand and
              <br /> agree that we have the sole discretion to determine whether
              your
              <br /> attribution(s) and use of Our Marks is in accordance with
              the above
              <br /> requirements and any applicable guidelines.
            </p>
            <p>
              5.2 In the course of promoting, marketing, or demonstrating the
              Services
              <br /> you are using, we may use your company or product name and
              logos. By
              <br /> accessing and using the Services, you hereby grant us all
              necessary
              <br /> rights for these purposes.
            </p>
          </div>
          <h4>6. DATA PROTECTION</h4>
          <div className="legal-page-margin">
            <p>
              6.1 <b>Privacy</b>
            </p>
            <div className="legal-page-margin">
              <p>
                6.1.1 We collect certain data and information about you and your
                End
                <br /> Users in connection with your and your End Users’ use of
                the
                <br /> Services and otherwise in connection with these Terms,
                which does
                <br /> not contain any personally identifiable information. We
                collect and
                <br /> use all such data and information in accordance with our
                Privacy
                <br /> Policy.
              </p>
              <p>
                6.1.2 By accessing the Services, you agree to allow us to
                collect
                <br /> and use information from you and your End Users in
                relation to your
                <br /> experience with the Services to conduct research about
                the Services
                <br /> and to improve the products and user experience therein.
                You further
                <br /> agree that it is your responsibility to, and you shall,
                obtain all
                <br /> necessary consents from your End Users for our
                collection, use and
                <br /> disclosure of data in connection with providing you the
                Services.
                <br /> All such information collection, use and disclosure will
                be in
                <br /> accordance with our Privacy Policy.
              </p>
              <p>
                6.1.3 Nothing in these Terms prevents us from disclosing data to
                the
                <br /> extent required by law, subpoenas or court orders.
              </p>
            </div>
            <p>
              6.2 <b>Security</b>
            </p>
            <div className="legal-page-margin">
              <p>
                6.2.1 We will maintain administrative, physical and technical
                <br /> safeguards consistent with industry standards, which are
                designed to
                <br /> provide security, confidentiality and integrity of all
                data
                <br /> processed by us.
              </p>
            </div>
            <p>
              6.3 <b>Your data and data compliance obligations</b>
            </p>
            <div className="legal-page-margin">
              <p>
                6.3.1 You retain all right, title, and interest in and to any
                data
                <br /> submitted by End Users or you (<b>“Your Data”</b>) to or
                through our
                <br /> Services. Subject to these Terms, and to the extent
                necessary to
                <br /> provide the Services to you, you grant us a worldwide,
                limited term
                <br /> licence during the term of your subscription to use,
                reproduce,
                <br /> adapt, modify, and translate, Your Data. Solely to the
                extent that
                <br /> reformatting Your Data for display in the course of
                providing the
                <br /> Services constitutes a modification or derivative work,
                the
                <br /> foregoing license also includes the right to make
                modifications and
                <br /> derivative works. We may also access your accounts in
                order to
                <br /> respond to your Support requests.
              </p>
              <p>
                6.3.2 You will provide all required disclosures to and will
                obtain
                <br /> and maintain all required consents from End Users to
                allow our
                <br /> provision of the Services to you and End Users. You will
                provide
                <br /> evidence of any such consent upon our reasonable request.
                You agree
                <br /> to use any information (personal or otherwise) provided
                to you by
                <br /> End Users, or otherwise through the Services, in a lawful
                and
                <br /> responsible manner.
              </p>
              <p>
                6.3.3 By accessing or using the Services, you represent and
                warrant
                <br /> that:
              </p>
              <div className="legal-page-margin">
                <p>
                  (a) you have obtained all necessary rights, releases and
                  <br /> permissions to submit all Your Data to us and to grant
                  the
                  <br /> rights granted to us in these Terms;
                </p>
                <p>
                  (b) you will not submit to the Services (or use the Services
                  to
                  <br /> collect) any sensitive data, including data relating to
                  racial
                  <br /> or ethnic origin, sexual orientation, political
                  opinions,
                  <br /> religious or philosophical beliefs, trade union
                  membership,
                  <br /> genetic data, biometric data, medical information, and
                  any
                  <br /> protected data similar to the foregoing under
                  applicable laws;
                  <br /> and
                </p>
                <p>
                  (c) Your Data and its submission and use as you authorise in
                  <br /> connection with these Terms:
                </p>
                <div className="legal-page-margin">
                  <p>(i) is complete, true and current;</p>
                  <p>
                    (ii) does not infringe any third-party intellectual
                    <br /> property, privacy, publicity or other rights;
                  </p>
                  <p>
                    (iii) does not violate any of your or third-party policies
                    <br /> or terms governing Your Data;
                  </p>
                  <p>
                    (iv) is not libellous, defamatory, obscene, pornographic,
                    <br /> abusive, indecent, threatening, harassing, hateful,
                    <br /> offensive or otherwise violates any law or right of
                    any
                    <br /> third party; and
                  </p>
                  <p>
                    (v) does not contain a virus, trojan horse, worm, time bomb
                    <br /> or other computer programming routine or engine that
                    is
                    <br /> intended to damage, detrimentally interfere with,
                    <br /> surreptitiously intercept or expropriate any system,
                    data or
                    <br /> information. Other than our express obligations under
                    Clause
                    <br /> 6 (Data Protection) and Clause 8 (Confidentiality),
                    we
                    <br /> assume no responsibility or liability for Your Data,
                    and you
                    <br /> are solely responsible for Your Data and the
                    consequences of
                    <br /> submitting and using it with the Services.
                  </p>
                </div>
              </div>
              <p>
                6.3.4 We have no obligation to monitor any content uploaded to
                or
                <br /> sent through our Services. Nonetheless, if we deem such
                action
                <br /> necessary based on your violation of these Terms,
                including the
                <br /> Acceptable Use Policy and the Privacy Policy, or in
                response to
                <br /> takedown requests that we receive, we may
              </p>
              <div className="legal-page-margin">
                <p>(a) remove Your Data from the Services, and/or</p>
                <p>
                  (b) suspend your access to the Services. We will use
                  reasonable
                  <br /> efforts to provide you with advance notice of removals
                  and
                  <br /> suspensions when practicable, but if we determine that
                  your
                  <br /> actions endanger the operation of the Services or other
                  users,
                  <br /> we may suspend your access or remove Your Data
                  immediately
                  <br /> without notice. We have no liability to you for
                  removing or
                  <br /> deleting Your Data from or suspending your access to
                  any
                  <br /> Services as described in this Clause 6.3.4.
                </p>
              </div>
            </div>
            <p>
              6.4 <b>Use of third party products</b>
            </p>
            <div className="legal-page-margin">
              <p>
                6.4.1 You (including your End Users) may choose to use or
                procure
                <br /> other third party products or services in connection with
                the
                <br /> Services, or implementation, customisation, training or
                other
                <br /> services in relation thereto. Your receipt or use of any
                third party
                <br /> products or services (and the third parties’ use of any
                of Your
                <br /> Data) is subject to a separate agreement between you and
                the third
                <br /> party provider. If you enable or use third party products
                or
                <br /> services with the Services, we will allow the third party
                providers
                <br /> to access or use Your Data as required for the
                interoperation of
                <br /> their products and services with the Services. This may
                include
                <br /> transmitting, transferring, modifying or deleting Your
                Data, or
                <br /> storing Your Data on systems belonging to the third party
                providers
                <br /> or other third parties. Any third party provider’s use of
                Your Data
                <br /> is subject to the applicable agreement between you and
                such third
                <br /> party provider. We are not responsible for any access to
                or use of
                <br /> Your Data by third party providers or their products or
                services, or
                <br /> for the security or privacy practices of any third party
                provider or
                <br /> its products or services.
              </p>
              <p>
                6.4.2 You are solely responsible for your decision to permit any
                <br /> third party provider or third party product or service to
                use Your
                <br /> Data. It is your responsibility to carefully review the
                agreement
                <br /> between you and the third party provider, as provided by
                the
                <br /> applicable third party provider. We disclaim all
                liability and
                <br /> responsibility for any third party products or services
                (whether
                <br /> support, availability, security or otherwise) or for the
                acts or
                <br /> omissions of any third party providers or vendors.
              </p>
            </div>
          </div>
          <h4>7. INTELLECTUAL PROPERTY</h4>
          <div className="legal-page-margin">
            <p>
              7.1 Subject to these Terms, we grant you a limited, revocable,
              <br /> non-exclusive, non-sub licensable, non-transferrable
              license to access
              <br /> and use the Services solely in accordance with these Terms
              during the
              <br /> term of your subscription. No other proprietary or
              ownership right is
              <br /> conveyed to you, irrespective of the use of terms such as
              “purchase” or
              <br /> “sale”. Except as provided in this Clause 7.1, you obtain
              no rights
              <br /> under these Terms from us, our affiliates or our licensors
              to the
              <br /> Services, including any related intellectual property
              rights.
            </p>
            <p>
              7.2 Except as otherwise expressly permitted in these Terms,
              neither you
              <br /> nor any End User will:
            </p>
            <div className="legal-page-margin">
              <p>
                7.2.1 reproduce, modify, adapt distribute, alter, tamper with,
                <br /> repair, or otherwise create derivative works of the
                Services or any
                <br /> content offered therein;
              </p>
              <p>
                7.2.2 rent, lease, distribute, sell, sublicense, transfer or
                provide
                <br /> access to the Services to a third party;
              </p>
              <p>
                7.2.3 use the Services for the benefit of any third party except
                <br /> with our prior written consent;
              </p>
              <p>
                7.2.4 interfere with or otherwise circumvent mechanisms in the
                <br /> Services to avoid incurring fees or exceeding usage
                limits or
                <br /> quotas;
              </p>
              <p>
                7.2.5 reverse engineer, disassemble, decompile, translate or
                <br /> otherwise apply any other process or procedure to obtain
                or derive
                <br /> the source code, underlying ideas, algorithms, file
                formats or
                <br /> non-public APIs to any Services, except to the extent
                expressly
                <br /> permitted by applicable law (and then only upon advance
                notice to
                <br /> us);
              </p>
              <p>
                7.2.6 remove or obscure any proprietary or other notices
                contained
                <br /> in any Services;
              </p>
              <p>
                7.2.7 use the Services for competitive analysis or to build
                <br /> competitive products;
              </p>
              <p>
                7.2.8 publicly disseminate information regarding the performance
                of
                <br /> the Services without our prior written consent or
                acknowledgement;
                <br /> or
              </p>
              <p>
                7.2.9 encourage or assist any third party to do any of the
                <br /> foregoing.
              </p>
            </div>
            <p>
              7.3 In accessing and/or using the Services, you grant us a
              worldwide,
              <br /> limited term licence during the term of your subscription
              to use,
              <br /> reproduce, adapt, modify, and translate, the information
              that you
              <br /> provide to us for the purpose of providing you with the
              Services. Solely
              <br /> to the extent that reformatting such information for
              display or use in
              <br /> our Services constitutes a modification or derivative work,
              the
              <br /> foregoing license also includes the right to make
              modifications and
              <br /> derivative works. We may also access your accounts in order
              to respond
              <br /> to Support requests.
            </p>
            <p>
              7.4 If you or your End Users provide any feedback or suggestions
              <br /> (<b>“Suggestions”</b>) to us or our affiliates, we and our
              affiliates
              <br /> will be entitled to freely use, copy, disclose, license,
              distribute and
              <br /> exploit any Suggestions in any manner without any
              obligation, royalty or
              <br /> restriction based on intellectual property rights or
              otherwise. You
              <br /> hereby irrevocably assign to us all right, title, and
              interest in and to
              <br /> the Suggestions and agree to provide us any assistance we
              require to
              <br /> document, perfect, and maintain our rights in the
              Suggestions. No
              <br /> Suggestions will be considered Confidential Information,
              and nothing in
              <br /> these Terms limits our right to independently use, develop,
              evaluate, or
              <br /> market products or services, whether incorporating
              Suggestions or
              <br /> otherwise.
            </p>
          </div>
          <h4>8. CONFIDENTIALITY</h4>
          <div className="legal-page-margin">
            <p>
              8.1 Except as otherwise set forth in these Terms, each party
              agrees that
              <br /> all code, inventions, know-how and business, technical and
              financial
              <br /> information disclosed to such party (
              <b>“Receiving Party”</b>) by the
              <br /> disclosing party (<b>“Disclosing Party”</b>) constitute the
              confidential
              <br /> property of the Disclosing Party (
              <b>“Confidential Information”</b>),
              <br /> provided that it is identified as confidential at the time
              of disclosure
              <br /> or should be reasonably known by the Receiving Party to be
              confidential
              <br /> or proprietary due to the nature of the information
              disclosed and the
              <br /> circumstances surrounding the disclosure. Confidential
              Information does
              <br /> not include information which the Receiving Party can
              document:
            </p>
            <div className="legal-page-margin">
              <p>
                (a) was rightfully in its possession or known to it prior to
                receipt
                <br /> of the Confidential Information;
              </p>
              <p>
                (b) is or has become public knowledge through no fault of the
                <br /> Receiving Party;
              </p>
              <p>
                (c) is rightfully obtained by the Receiving Party from a third
                party
                <br /> without breach of any confidentiality obligation; or
              </p>
              <p>
                (d) is independently developed by employees of the Receiving
                Party
                <br /> who had no access to such information.
              </p>
            </div>
            <p>
              8.2 Any of our technology and any performance information relating
              to
              <br /> the Services will be deemed our Confidential Information
              without any
              <br /> marking or further designation. Except as expressly
              authorised herein,
              <br /> the Receiving Party will
            </p>
            <div className="legal-page-margin">
              <p>
                (a) hold in confidence and not disclose any Confidential
                Information
                <br /> to third parties; and
              </p>
              <p>
                (b) not use Confidential Information for any purpose other than
                <br /> fulfilling its obligations and exercising its rights
                under these
                <br /> Terms.
              </p>
            </div>
            <p>
              8.3 The Receiving Party may disclose Confidential Information:
            </p>
            <div className="legal-page-margin">
              <p>
                8.3.1 to its employees, agents, contractors and other
                <br /> representatives having a legitimate need to know,
                provided that such
                <br /> recipients are bound to confidentiality obligations no
                less
                <br /> protective of the Disclosing Party than this Clause 8 and
                that the
                <br /> Receiving Party remains responsible for compliance by
                such
                <br /> recipients with the terms of this Clause 8; or
              </p>
              <p>
                8.3.2 if required to do so pursuant to a regulation, law or
                court
                <br /> order (but only to the minimum extent required to comply
                with such
                <br /> regulation or order and with advance notice to the
                Disclosing
                <br /> Party).
              </p>
            </div>
            <p>
              8.4 The Receiving Party acknowledges that disclosure of
              Confidential
              <br /> Information would cause substantial harm for which damages
              alone would not
              <br /> be a sufficient remedy, and therefore that upon any such
              disclosure by the
              <br /> Receiving Party the Disclosing Party will be entitled to
              appropriate
              <br /> equitable relief in addition to whatever other remedies it
              might have at
              <br /> law.
            </p>
          </div>
          <h4>9. EXPORT RESTRICTIONS</h4>
          <div className="legal-page-margin">
            <p>
              9.1 You agree to comply with all applicable export and import laws
              and
              <br /> regulations in your access to and use of the Services (or
              any part
              <br /> thereof). You shall not (and shall not allow any third
              party to) use,
              <br /> export or allow the export or re-export of any part of the
              Services into
              <br /> (or to a national or resident of) any embargoed or
              terrorist-supporting
              <br /> country, or otherwise in violation of any export or import
              restrictions
              <br /> under applicable law.
            </p>
            <p>
              9.2 In accessing and using the Services, you represent and warrant
              that
              <br /> you:
            </p>
            <div className="legal-page-margin">
              <p>
                9.2.1 are not located in, under the control of, or a national or
                <br /> resident of any such prohibited country;
              </p>
              <p>
                9.2.2 are not, and you do not act on behalf of a designated
                <br /> individual or entity, as defined in the respective United
                Nations
                <br /> Regulations under the United Nations Act (Cap. 339 of
                Singapore);
                <br /> and
              </p>
              <p>
                9.2.3 will not access, use or provide the Services to any End
                User
                <br /> for any prohibited end use.
              </p>
            </div>
          </div>
          <p>10. INDEMNIFICATION</p>
          <div className="legal-page-margin">
            <p>
              10.1 You will defend, indemnify and hold harmless us (and our
              <br /> affiliates, officers, directors, agents and employees) from
              and against
              <br /> any and all claims, costs, damages, losses, liabilities and
              expenses
              <br /> (including reasonable attorneys’ fees and costs) resulting
              from any
              <br /> claim arising from or related to:
            </p>
            <div className="legal-page-margin">
              <p>10.1.1 your use or misuse of the Services</p>
              <p>
                10.1.2 any claims or disputes brought by your End Users arising
                out
                <br /> of their use of the Services;
              </p>
              <p>10.1.3 your breach (or alleged breach) of these Terms; or</p>
              <p>
                10.1.4 your infringement upon any intellectual property or other
                <br /> proprietary right of any person or entity.
              </p>
            </div>
            <p>
              10.2 We may, at our own expense, assume the defence and control of
              any
              <br /> matter otherwise subject to indemnification by you. Doing
              so shall not
              <br /> excuse your indemnity obligations in these Terms. The terms
              of this
              <br /> Clause 9 will survive any expiration, termination or
              cancellation of
              <br /> these Terms.
            </p>
          </div>
          <h4>11. DISCLAIMERS AND LIMITATION OF LIABILITY</h4>
          <div className="legal-page-margin">
            <p>
              11.1 The Services are provided on an “as is” and “as available”
              basis.
              <br /> Except to the extent prohibited by applicable law, or to
              the extent any
              <br /> statutory rights apply that cannot be excluded, limited or
              waived, we
              <br /> and our affiliates and licensors
            </p>
            <div className="legal-page-margin">
              <p>
                (a) make no representations or warranties of any kind, whether
                <br /> express, implied, statutory or otherwise regarding the
                Services, and
                <br /> (b) disclaim al warranties, including any implied or
                express
                <br /> warranties:
              </p>
              <div className="legal-page-margin">
                <p>
                  (i) of merchantability, satisfactory quality, fitness for a
                  <br /> particular purpose, non-infringement, or quiet
                  enjoyment;
                </p>
                <p>
                  (ii) arising out of any course of dealing or usage of trade;
                </p>
                <p>
                  (iii) that the Services will be uninterrupted, error-free, or
                  <br /> free of harmful components;
                </p>
                <p>(iv) that any errors or defects will be corrected; and</p>
                <p>
                  (v) that any content will be secure or not otherwise lost or
                  <br /> altered.
                </p>
              </div>
            </div>
            <p>
              11.2 Except as stated in these Terms, we (and our affiliates,
              officers,
              <br /> licensors, and/or contractors) shall not be liable for any
              indirect,
              <br /> incidental, special or consequential damages, even if
              advised of the
              <br /> possibility of such damages. To the extent that the
              exclusion or
              <br /> limitation of liability as set forth herein is not
              permitted under any
              <br /> applicable law, our liability shall be limited to the full
              extent
              <br /> permitted under such applicable law.
            </p>
            <p>
              11.3 All material and/or data downloaded or obtained through the
              <br /> Services is at your own risk and the risk of your End
              Users. You are
              <br /> solely responsible for the use or your possession of such
              data or
              <br /> material. We do not:
            </p>
            <div className="legal-page-margin">
              <p>
                (a) guarantee the accuracy, completeness, or usefulness of any
                <br /> information on the Services; or
              </p>
              <p>
                (b) adopt, endorse or accept responsibility for the accuracy or
                <br /> reliability of any opinion, advice, or statement made by
                any party
                <br /> that appears on the Services. Under no circumstances will
                we or our
                <br /> affiliates be responsible for any loss or damage
                resulting from your
                <br /> reliance on information or other content posted through
                the Services
                <br /> or transmitted to or by any End Users.
              </p>
            </div>
          </div>
          <h4>12. TERMINATION</h4>
          <div className="legal-page-margin">
            <p>
              12.1 These Terms are effective immediately and expire on the date
              of
              <br /> expiration or termination of your access to or use of the
              Services. 12.2
              <br /> You may choose to stop using the Services and terminate
              these Terms at
              <br /> any time for any reason upon written notice to us, but upon
              any such
              <br /> termination
            </p>
            <div className="legal-page-margin">
              <p>
                (a) you will not be entitled to a refund of any pre-paid fees
                and
              </p>
              <p>
                (b) if you have any fees or charges outstanding, they will
                become
                <br /> immediately due and payable.
              </p>
            </div>
            <p>
              12.3 We may terminate these Terms at any time and for any reason.
              We may
              <br /> monitor our systems for excessive consumption of network
              resources and
              <br /> may take technical or other remedies deemed necessary to
              prevent or
              <br /> eliminate any excessive consumption. If we deem your use to
              be
              <br /> excessive, we may terminate your account or adjust the
              price of the
              <br /> Services.
            </p>
            <p>
              12.4 In the event of termination, you shall immediately cease your
              use
              <br /> of the Services, and destroy or return all our Confidential
              Information.
              <br /> All provisions in these Terms regarding confidentiality,
              proprietary
              <br /> rights, limitation of liability, indemnity, and
              non-disclosure shall
              <br /> survive the termination of these Terms.
            </p>
          </div>
          <h4>13. GENERAL PROVISIONS</h4>
          <div className="legal-page-margin">
            <p>
              13.1 <b>Definitions</b>
            </p>
            <p>In these Terms, the following definitions shall apply:</p>
            <div className="legal-page-margin">
              <p>
                <b>“Acceptable Use Policy”</b> means our Acceptable Use Policy
                <br /> available at
                <a href="/legal/#acceptable-use-policy">
                  https://www.triniti.ai/legal/acceptable-use-policy
                </a>
                ;
              </p>
              <p>
                <b>“Our Marks”</b> means any trademarks, service marks, service
                or
                <br /> trade names, logos, and other designations of us and our
                affiliates
                <br /> that we may make available to you in connection with
                these Terms;
              </p>
              <p>
                “Privacy Policy” means our “Privacy Policy” available at
                <a href="/legal#privacy-policy">
                  https://www.triniti.ai/legal#privacy-policy/
                </a>
                ;
              </p>
              <p>
                <b>“Support Policy”</b> means our support policy available at
                <a href="/legal#support-and-service-level-agreement">
                  https://www.triniti.ai/legal/#support-and-service-level-agreement
                </a>
                ; and
              </p>
              <p>
                <b>“Website”</b> means our website(s) located at
                <a href="/legal">https://www.triniti.ai/legal</a>
              </p>
            </div>
            <p>
              13.2 <b>Severability</b>
            </p>
            <p>
              If any provision of these Terms is held by a court of competent
              <br /> jurisdiction to be invalid, void, or unenforceable, the
              remaining
              <br /> provisions of these Terms will not be affected, impaired or
              invalidated.
              <br /> If the absence of the provision adversely affects the
              substantive rights
              <br /> of a party, the parties agree to replace the provision with
              a new
              <br /> provision that closely approximates the economic and
              proprietary results
              <br /> intended by the parties.
            </p>
            <p>
              13.3 <b>Force majeure</b>
            </p>
            <p>
              Any delays in or failure by either party in the performance of any
              <br /> obligation under these Terms shall be excused to the extent
              that such
              <br /> failure or delay is caused by occurrences beyond the
              party’s reasonable
              <br /> control, including acts of God, storms, hurricane,
              earthquakes, riots,
              <br /> war (whether declared or not), sabotage, interruption or
              failure of
              <br /> telecommunication or digital transmission links, Internet
              slow-downs or
              <br /> failures, and any other cause that cannot reasonably be
              foreseen or
              <br /> controlled by such party. A party will not be liable as
              result of
              <br /> failures or errors related to the use or operation of the
              Internet.
            </p>
            <p>
              13.4 <b>Entire agreement</b>
            </p>
            <p>
              These Terms constitute the entire agreement between us regarding
              our
              <br /> Services, and supersede and replace any prior or
              contemporaneous
              <br /> representations, understandings, agreements, or
              communications between
              <br /> you and us, whether written or verbal, regarding the
              Services.
            </p>
            <p>
              13.5 <b>Amendments</b>
            </p>
            <div className="legal-page-margin">
              <p>
                13.5.1 These Terms may be amended or replaced by us at any time
                by
                <br /> posting a revised version on our Website or by otherwise
                notifying
                <br /> you in accordance with Clause 13.8. The amended terms
                will become
                <br /> effective upon posting, or if we notify you, as stated in
                such
                <br /> notification. If a revision is material, we will try to
                provide
                <br /> reasonable notice to you prior to any new terms taking
                effect. What
                <br /> constitutes a material change will be determined at our
                sole
                <br /> discretion.
              </p>
              <p>
                13.5.2 By continuing to use the Services after the effective
                date of
                <br /> any amendments to these Terms, you agree to be bound by
                the amended
                <br /> Terms. It is your responsibility to check the Website
                regularly for
                <br /> amendments to these Terms. If you do not agree to the
                amended Terms,
                <br /> please stop using the Services.
              </p>
            </div>
            <p>
              13.6 <b>Waiver</b>
            </p>
            <p>
              No waiver, delay or discharge by a party will be valid unless in
              writing
              <br /> and signed by an authorised representative of the party
              against which
              <br /> its enforcement is sought. Neither the failure of either
              party to
              <br /> exercise any right of termination nor the waiver of any
              default will
              <br /> constitute a waiver of the rights granted in these Terms
              with respect to
              <br /> any subsequent or other default.
            </p>
            <p>
              13.7 <b>Assignment</b>
            </p>
            <p>
              You may not assign or transfer, or purport to assign or transfer,
              any of
              <br /> your rights, duties, or obligations under these Terms to
              any person or
              <br /> entity, in whole or in part, whether by assignment, merger,
              transfer of
              <br /> assets, sale of stock, operation of law, or otherwise. We
              may assign or
              <br /> transfer these Terms in our sole discretion.
            </p>
            <p>
              13.8 <b>Notices</b>
            </p>
            <p>
              All questions, notices, demands, or requests to us with respect to
              these
              <br /> Terms shall be made in writing to: support@triniti.ai . All
              notices to
              <br /> you shall be made by posting the notice on the Website.
            </p>
            <p>
              13.9 <b>Governing law and jurisdiction</b>
            </p>
            <p>
              These Terms shall be governed and construed in accordance with the
              laws
              <br /> of Singapore, without regard to its conflict of law
              provisions.
            </p>
            <p>
              13.10 <b>Arbitration</b>
            </p>
            <p>
              Any dispute arising out of or in connection with these Terms,
              including
              <br /> any question regarding its existence, validity or
              termination, shall be
              <br /> referred to and finally resolved by arbitration
              administered by the
              <br /> Singapore International Arbitration Centre in accordance
              with the
              <br /> Arbitration Rules of the Singapore International
              Arbitration Centre for
              <br /> the time being in force, which rules are deemed to be
              incorporated by
              <br /> reference in this clause. The seat of the arbitration shall
              be
              <br /> Singapore. The Tribunal shall consist of one arbitrator(s).
              The language
              <br /> of the arbitration shall be English.
            </p>
          </div>
          <p>
            <b>Date: 1st May ,</b>
          </p>
          <p>
            <b>2019 Version 2.0</b>
          </p>
        </section>
        <section
          className="legal-section acceptable-use-policy"
          id="acceptable-use-policy"
        >
          <h2>ACCEPTABLE USE POLICY</h2>
          <p>
            This Acceptable Use Policy (<b>“Policy“</b>) describes prohibited
            uses of the
            <br /> services, software and websites (“Services“) provided by
            Active Intelligence
            <br /> Pte Ltd (<b>“us“</b>,<b>“we“</b>, or
            <b>“our“</b>). By using the Services or accessing our
            <br /> website at
            <a href="/"> https://triniti.ai</a> (the
            <b>“Website“</b>), you agree to abide by the
            <br /> latest version of this Policy. If you violate the provisions
            of this Policy,
            <br /> or authorise or help others to do so, we may suspend or
            terminate your use
            <br /> of the Services.
          </p>
          <p>
            We reserve the right to amend, alter, or modify this Policy at any
            time. Any
            <br /> amendment or modification is effective when posted and any
            use of its
            <br /> Services after the posting of a modification or amendment
            will be considered
            <br /> acceptance of those modifications.
          </p>
          <p>
            Except where otherwise specified, all capitalised terms in this
            Policy shall
            <br /> have the same meaning as they have in the Terms of Service
            (available at
            <br />
            <a href="/legal/#terms-of-service">
              https://triniti.ai/legal/#terms-of-service
            </a>
            ), which also includes the provisions in this Policy.
          </p>
          <h4>1. No illegal, harmful, or offensive use or content</h4>
          <div className="legal-page-margin">
            <ul className="legal-circle-list">
              <li>
                You may not use, or encourage, promote, facilitate or instruct
                <br /> others to use, the Services for any illegal, harmful or
                <br /> offensive use, or to transmit, store, display, distribute
                or
                <br /> otherwise make available content that is illegal,
                harmful, or
                <br /> offensive. Prohibited activities or content include but
                are not
                <br /> limited to:
              </li>
            </ul>
            <div className="legal-page-margin">
              <ul className="legal-square-list">
                <li>
                  any illegal activities under applicable law, including
                  <br /> advertising, transmitting, or otherwise making
                  available
                  <br /> gambling sites or services or disseminating, promoting,
                  <br /> facilitating, displaying, performing, sending,
                  receiving or
                  <br /> storing any content that is obscene, pornographic,
                  lewd,
                  <br /> lascivious, or excessively violent, regardless of
                  whether
                  <br /> the material or its dissemination is unlawful.
                  Prohibited
                  <br /> activities also include advocating or encouraging
                  violence
                  <br /> against any government, organisation, group, individual
                  or
                  <br /> property, or providing instruction, information, or
                  <br /> assistance in causing or carrying out such violence,
                  <br /> regardless of whether such activity is unlawful;
                  activities
                  <br /> that may be harmful to others, our operations or
                  reputation,
                  <br /> including without limitation offering or disseminating
                  <br /> fraudulent goods, services, schemes, or promotions
                  (e.g.,
                  <br /> make-money-fast schemes, ponzi and pyramid schemes,
                  <br /> phishing, or pharming), obtaining unauthorised access
                  to any
                  <br /> system, network, service, or account, or engaging in
                  other
                  <br /> deceptive practices;
                </li>
                <li>
                  content that infringes or misappropriates the intellectual
                  <br /> property or proprietary rights of others, including
                  without
                  <br /> limitation, accessing, sending, receiving, displaying,
                  <br /> performing, disclosing, storing, or executing any
                  content
                  <p></p>
                  <div className="legal-page-margin">
                    <p>
                      (a) in violation of any copyright, right of publicity,
                      <br /> patent, trademark, service mark, trade name, trade
                      <br /> secret or other intellectual property right,
                    </p>
                    <p>(b) in violation of any applicable agreement, or</p>
                    <p>
                      (c) without authorisation, or deleting or altering
                      <br /> author attributions, copyright notices, or
                      trademark
                      <br /> notices, unless expressly permitted in writing by
                      the
                      <br /> owner;
                    </p>
                  </div>
                </li>
                <li>
                  content that is defamatory, obscene, abusive, invasive of
                  <br /> privacy, or otherwise objectionable, including
                  publishing or
                  <br /> posting other people’s private and confidential
                  information
                  <br /> without their express permission, collecting or
                  gathering
                  <br /> other people’s personal information (including account
                  names
                  <br /> or information) from our Services, or posting any
                  <br /> defamatory, abusive, obscene, profane, threatening,
                  <br /> harassing, racially offensive, discriminatory or
                  otherwise
                  <br /> offensive or illegal material;
                </li>
                <li>
                  accessing or searching any part of the services by any means
                  <br /> other than our publicly supported interfaces (e.g.,
                  <br /> scraping); and
                </li>
                <li>
                  interfering with service to any user, site, account, system,
                  <br /> or network by use of any program, script, command, or
                  <br /> otherwise, and content or other computer technology
                  that may
                  <br /> damage, interfere with, surreptitiously intercept, or
                  <br /> expropriate any system, program, or data, including
                  viruses,
                  <br /> Trojan horses, worms, time bombs, or cancelbots.
                </li>
              </ul>
            </div>
          </div>
          <h4>2. No Network Abuse</h4>
          <div className="legal-page-margin">
            <ul className="legal-circle-list">
              <li>
                You may not make network connections to any users, applications,
                <br /> hosts, or networks unless you have permission to
                communicate
                <br /> with them. Prohibited activities include but are not
                limited to:
              </li>
            </ul>
            <div className="legal-page-margin">
              <ul className="legal-square-list">
                <li>
                  reselling the Services, in whole or in part, to any entity
                  <br /> or individual, without our prior written consent, or
                  <br /> misrepresenting your relationship with us;
                </li>
                <li>
                  using manual or automatic processes to retrieve, index,
                  <br /> datamine, or in any way reproduce or circumvent the
                  <br /> navigational structure or presentation of the Services
                  or
                  <br /> their contents;
                </li>
                <li>
                  monitoring or crawling that impairs or disrupts the system
                  <br /> being monitored or crawled;
                </li>
                <li>
                  overwhelming our infrastructure by imposing an unreasonably
                  <br /> large load on our systems that consume extraordinary
                  <br /> resources, so that they either cannot respond to
                  legitimate
                  <br /> traffic or respond so slowly that it becomes
                  ineffective;
                </li>
                <li>
                  interfering with the proper functioning of any system
                  <br /> relating to our Website or Services, including any
                  <br /> deliberate attempt to overload a system by mail
                  bombing,
                  <br /> news bombing, broadcast attacks, or flooding
                  techniques;
                </li>
                <li>
                  operating network services like open proxies, open mail
                  <br /> relays, or open recursive domain name servers;
                </li>
                <li>
                  using manual or electronic means to interfere with or
                  <br /> otherwise circumvent mechanisms in the Services to
                  avoid
                  <br /> incurring fees or exceeding usage limits or quotas; and
                </li>
                <li>
                  any test with the purpose of placing traffic or load on the
                  <br /> system to stress the system utilising automated tools
                  or
                  <br /> manual means without our explicit authorisation.
                </li>
              </ul>
            </div>
          </div>
          <h4>3. No Abuse</h4>
          <p>
            You will not distribute, publish, send, or facilitate unsolicited
            mass
            <br /> e-mailings, messages, promotions, advertising, or
            solicitations, including
            <br /> commercial advertising and informational announcements. You
            will not alter
            <br /> or obscure mail headers or assume a sender’s identity without
            the sender’s
            <br /> explicit permission. You will not collect replies to messages
            sent from an
            <br /> Internet service provider if those messages violate this
            Policy or the
            <br /> acceptable use policy of that provider.
          </p>
          <h4>4. No Security Violations</h4>
          <div className="legal-page-margin">
            <ul className="legal-circle-list">
              <li>
                You may not use the Services to violate the security or
                <br /> integrity of any network, computer or communications
                system,
                <br /> software application, or network or computing device
                (each, a
                <br />
                <b>“System”</b>). Prohibited activities include but are not
                limited to:
              </li>
            </ul>
            <div className="legal-page-margin">
              <ul className="legal-square-list">
                <li>
                  tampering with, reverse-engineering, or hacking our
                  <br /> Services, circumventing any security or authentication
                  <br /> measures, or attempting to gain unauthorised access to
                  the
                  <br /> services, related systems, networks, or data;
                </li>
                <li>
                  monitoring or deciphering data or traffic on a System
                  <br /> without permission;
                </li>
                <li>
                  forging TCP-IP packet headers, e-mail headers, or any part
                  <br /> of a message or otherwise manipulating identifiers in
                  order
                  <br /> to disguise the origin or route of any information
                  <br /> transmitted through the Services;
                </li>
                <li>
                  framing or mirroring any part of the Services without our
                  <br /> prior written authorisation;
                </li>
                <li>
                  using metatags or code or other devices containing any
                  <br /> reference to the Services in order to direct any person
                  to
                  <br /> any other website for any purpose;
                </li>
                <li>
                  transmitting any material that contains software viruses or
                  <br /> any other computer code, files or programs designed to
                  <br /> interrupt, destroy or limit the functionality of any
                  <br /> computer software or hardware or telecommunications
                  <br /> equipment; and
                </li>
                <li>
                  circumventing user authentication or security of any
                  <br /> application, host, network, or account, or interfering
                  with
                  <br /> service to any user, host or network (referred to as
                  “denial
                  <br /> of service attacks”).
                </li>
              </ul>
            </div>
          </div>
          <h4>5. Monitoring and Enforcement</h4>
          <div className="legal-page-margin">
            <ul className="legal-circle-list">
              <li>
                We reserve the right to determine, in our sole discretion and on
                <br /> a case-by-case basis, what actions will be taken in
                response to
                <br /> a violation of this Policy. We reserves the right to
                investigate
                <br /> suspected or alleged violation of this Policy, including
                <br /> gathering information from you, our registered users, the
                <br /> complaining party, and examining material that has been
                stored
                <br /> on our servers.
              </li>
              <li>
                Nothing contained in this Policy shall be construed to limit our
                <br /> actions or remedies in any manner. We reserve the right
                to take
                <br /> any and all additional actions we may deem appropriate
                with
                <br /> respect to such activities, including but are not limited
                to
                <br /> taking action to recover the costs and expenses of
                identifying
                <br /> offenders and barring their access to the Services and
                levying
                <br /> cancellation charges to cover our costs in the event of
                <br /> termination for the causes outlined above. We reserve at
                all
                <br /> times all rights and remedies available to us with
                respect to
                <br /> such activities at law or in equity.
              </li>
            </ul>
          </div>
          <h4>6. Reporting of Violations of this Policy</h4>
          <div className="legal-page-margin">
            <ul className="legal-circle-list">
              <li>
                If you become aware of any violation of this Policy, you will
                <br /> immediately notify us and provide us with assistance, as
                <br /> requested, to stop or remedy the violation. To report any
                <br /> violation of this Policy, please contact us at:
              </li>
            </ul>
          </div>
          <p>support@triniti.ai</p>
          <ul className="legal-circle-list">
            <li>
              You are obliged to immediately notify us of any breach, or
              attempted
              <br /> breach, of security once such breach or attempted breach is
              made
              <br /> known to you. You are responsible for ensuring that
              connections or
              <br /> interfaces are configured in a secure manner. You may not,
              through
              <br /> action or inaction, allow others (including your End Users)
              to use
              <br /> the Services for illegal or inappropriate activities.
            </li>
          </ul>
          <h4>YOUR Responsibility for END Users</h4>
          <p>
            You are responsible for the activities of your End Users. By
            accessing or
            <br /> using the Services, you agree to ensure that your customers,
            <br /> representatives, or End Users abide by this Policy.
            Complaints we receive
            <br /> about your customers, representatives, or End Users will be
            forwarded to
            <br /> your primary contact for action. If the provisions in this
            Policy are
            <br /> violated by your customers, representatives or End Users, we
            reserve the
            <br /> right to terminate services with you, or take action to stop
            your customers,
            <br /> representatives or End Users from violating this Policy as we
            deem
            <br /> appropriate, without notice.
          </p>
          <p>
            <b>Date: 01st March, 2019</b>
          </p>
          <p>
            <b>Version 1.0</b>
          </p>
        </section>
        <section
          className="legal-section support-and-service-level-agreement"
          id="support-and-service-level-agreement"
        >
          <h2>SUPPORT AND SERVICE LEVEL AGREEMENT</h2>
          <h4>Support Options</h4>
          <table className="legal-black-table">
            <tbody>
              <tr>
                <th></th>
                <th>
                  Community
                  <br /> Support
                </th>
                <th>
                  Standard
                  <br /> Support
                </th>
                <th>
                  Priority
                  <br /> Support
                </th>
                <th>
                  Premier
                  <br /> Support
                </th>
              </tr>
              <tr>
                <td>Deployment Options</td>
                <td>Cloud</td>
                <td>All</td>
                <td>All</td>
                <td>All</td>
              </tr>
              <tr>
                <td>Self Help Resources</td>
                <td>All</td>
                <td>All</td>
                <td>All</td>
                <td>All</td>
              </tr>
              <tr>
                <td>Support Portal</td>
                <td>–</td>
                <td>All</td>
                <td>All</td>
                <td>All</td>
              </tr>
              <tr>
                <td>Slack Support</td>
                <td>–</td>
                <td>All</td>
                <td>All</td>
                <td>All</td>
              </tr>
              <tr>
                <td>Response L1</td>
                <td>–</td>
                <td>8 hours</td>
                <td>4 hours</td>
                <td>1 hour</td>
              </tr>
              <tr>
                <td>Response L2</td>
                <td>–</td>
                <td>1 Day</td>
                <td>8 hours</td>
                <td>2 hours</td>
              </tr>
              <tr>
                <td>Response L3</td>
                <td>–</td>
                <td>2 Days *</td>
                <td>1 Day *</td>
                <td>8 hours</td>
              </tr>
              <tr>
                <td>Response L4</td>
                <td>–</td>
                <td>4 Days *</td>
                <td>2 Days *</td>
                <td>1 Day *</td>
              </tr>
              <tr>
                <td>Phone Support</td>
                <td>–</td>
                <td>Standard</td>
                <td>
                  L1,
                  <br /> L2 Advanced,
                  <br /> L3+ Standard
                </td>
                <td>
                  Advanced
                  <br /> Team
                </td>
              </tr>
              <tr>
                <td>Phone Support</td>
                <td>–</td>
                <td>–</td>
                <td>L1 only</td>
                <td>All</td>
              </tr>
              <tr>
                <td>Escalation Management</td>
                <td>–</td>
                <td>–</td>
                <td>–</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Post Incident Reports</td>
                <td>–</td>
                <td>–</td>
                <td>–</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Support Entitlement</td>
                <td>Anyone</td>
                <td>Admins Users</td>
                <td>Admins Users</td>
                <td>Admins Users</td>
              </tr>
              <tr>
                <td>Price</td>
                <td>Free</td>
                <td>Bundled with Standard Pricing</td>
                <td>Additional Cost</td>
                <td>Additional Cost</td>
              </tr>
            </tbody>
          </table>
          <p>* Business Days</p>
          <h4>Definition of Impact Levels</h4>
          <table className="legal-blue-table">
            <tbody>
              <tr>
                <th>Impact Level</th>
                <th>Definition</th>
              </tr>
              <tr>
                <td>Level 1</td>
                <td>
                  Production application down or major malfunction affecting
                  business
                  <br /> and high number of staff
                </td>
              </tr>
              <tr>
                <td>Level 2</td>
                <td>
                  Serious degradation of application performance or
                  functionality
                </td>
              </tr>
              <tr>
                <td>Level 3</td>
                <td>
                  Application issue that has a moderate impact to the business
                </td>
              </tr>
              <tr>
                <td>Level 4</td>
                <td>Issue or questions with limited business impact</td>
              </tr>
            </tbody>
          </table>
          <h4>Hours of Availability</h4>
          <p>
            <b>Weekend coverage</b> applies only to Priority and Premier Support
            <br /> programs. Weekends start from 5PM US Pacific timezone (UTC-8
            &amp; UTC-7 DST)
            <br /> until Monday 10AM Australian Eastern timezone (UTC+10 &amp;
            UTC+11 DST),
            <br /> spanning a total of 48 hours. During weekends when a Premier
            Support team
            <br /> member is not scheduled in advance, Premier Support requests
            are handled by
            <br /> the Standard Support team.
          </p>
          <p>
            <b>9/5 Support</b> – Hours of coverage include 8am – 5pm for the
            following
            <br /> timeszones: Pacific (UTC-8), Mountain (UTC-7), Central
            (UTC-6), Eastern
            <br /> (UTC-5). India is covered 10am-7pm(UTC+5.5). Singapore is
            covered 9am-6pm.
            <br /> Tickets will be handled in the office corresponding to the
            geo in which they
            <br /> are submitted.
          </p>
          <h4>Support Team</h4>
          <p>
            Our Advanced and Dedicated Senior Teams are both highly trained
            engineers
            <br /> who are ready to diagnose issues in your environment. Our
            dedicated senior
            <br /> support team familiarise themselves with three primary
            contacts from your
            <br /> company to learn your network and environment.
          </p>
          <h4>Entitlement for Community Support</h4>
          <p>
            Community Support is available to anyone who signs up. Users on a
            free trial
            <br /> are eligible for only community support.
          </p>
          <h4>Premier Support Named Contacts</h4>
          <p>
            Premier connects our top tier engineers with the customer and in
            order to
            <br /> ensure the best outcomes we limited the number of individuals
            within an
            <br /> account to three named contacts. This helps ensure the most
            knowledgeable
            <br /> individuals within our customers are connected with our most
            senior
            <br /> engineers to drive issues to conclusion faster. If additional
            named contacts
            <br /> are needed they can be purchased three at a time by
            purchasing additional
            <br /> Premier licenses. Account coverage pertains to all Active AI
            product
            <br /> licenses and does not include Open Source products or 3rd
            party plugins.
          </p>
          <h4>Support Includes</h4>
          <ul className="legal-page-margin">
            <li>
              Incident Support – Identifying and troubleshooting problems in the
              <br /> system
            </li>
            <li>Root cause analysis</li>
            <li>Assistance with issues during installation</li>
            <li>Assistance with issues during upgrades</li>
            <li>Identifying and creating needed bug reports</li>
            <li>Guidance around implementation and configuration</li>
          </ul>
          <h4>Support Does Not Include</h4>
          <ul className="legal-page-margin">
            <li>Customers without a valid maintenance agreement</li>
            <li>
              End of Life, Beta, Release Candidates or Development releases
            </li>
            <li>
              Customized versions of Active AI products (customized = original
              <br /> product code has been modified)
            </li>
            <li>Development questions or requests</li>
            <li className="legal-page-margin legal-square-list">
              For community based development support, please post these
              questions
              <br /> on Active AI Community.
            </li>
            <li>Third-party application integrations or third-party plugins</li>
            <li>Support of end-users (please see Knowledge Prerequisites)</li>
            <li>Product training</li>
          </ul>
          <h4>End of Life Policy</h4>
          <p>
            ActiveAI supports major versions for two years after the first major
            <br /> iteration of that version was released (for example, we
            support Triniti
            <br /> 1.1.x for 2 years after Triniti 1.0.0 was released).
          </p>
          <p>
            For versions that are supported, customers can raise issues via
            <br /> https://active.ai/support if a bug is discovered, it will be
            prioritised
            <br /> based on our Bug Fixing Policy and may require you to upgrade
            to the version
            <br /> which includes the fix. For critical security bugs, please
            see our Security
            <br /> Bugfix Policy on which versions we will back port critical
            security fixes
            <br /> to.
          </p>
          <h4>Eligibility and Software Maintenance</h4>
          <p>
            Software maintenance covers access to support and software product
            updates
            <br /> for the corresponding software license. Refer to the End User
            License
            <br /> Agreement for specific terms of agreement. After the active
            maintenance
            <br /> period expires, the software will continue to function, but
            you will no
            <br /> longer be able to access support or software updates
            (includes security bug
            <br /> fixes). Note that starter licenses include community support
            only.
            <br /> Support is open to system administrators and account holders.
            End-users will
            <br /> be redirected to a system administrator.
          </p>
          <h4>Knowledge Prerequisites</h4>
          <p>
            Administering ActiveAI products requires a baseline technical skill
            set,
            <br /> including but not limited to:
          </p>
          <ul>
            <li>
              Comfor running SQL commands and installing a database. IT’s best
              if
              <br /> you have a good DBA for database troubleshooting and
              administration.
            </li>
            <li>
              Comfort installing and maintaining production web technologies
            </li>
            <li>
              Comfortable with operating system administration and package
              <br /> management.
            </li>
          </ul>
          <p>
            Customers are responsible for administering and upgrading their own
            <br /> installations. ActiveAI Support will provide guidance on how
            to do this, but
            <br /> we will not be able to provide step by step maintenance and
            installtion.
          </p>
        </section>
        <section className="legal-section privacy-policy" id="privacy-policy">
          <h2>PRIVACY POLICY</h2>
          <h4>1. OVERVIEW</h4>
          <div className="legal-page-margin">
            <p>
              Active Intelligence Pte Ltd, a validly established company under
              the
              <br /> laws and regulations of Singapore (together with its parent
              company,
              <br /> subsidiaries, affiliates,
              <b>“us”,</b>
              <b>“we”</b>, or
              <b>“our”</b>)
              <br /> takes your privacy seriously.
            </p>
            <p>
              This privacy policy (“
              <b>Privacy Policy</b>“) sets out information about
              <br /> how we collect, store, process, transfer and use data that
              identifies or
              <br /> is associated with you, as a natural person and which is
              subject to the
              <br /> General Data Protection Regulation 2016/679 (the “
              <b>GDPR</b>”). This is
              <br /> the case for visitors and users of our Services located in
              the European
              <br /> Union (“
              <b>EU</b>“), European Economic Area (“
              <b>EEA</b>“) and other
              <br /> users who provide us with personal information that is
              subject to the
              <br /> GDPR. It is your responsibility to inform us if this
              Privacy Policy
              <br /> Addendum is applicable to you. When you use or access our
              Website, you
              <br /> expressly consent to our collection, storage, use and
              disclosure of your
              <br /> personal information as described this Privacy Policy.
            </p>
            <p>
              Except where otherwise specified, all capitalised terms in this
              Privacy
              <br /> Policy shall have the same meaning as they have in the
              Terms of Use
              <br /> (available at&nbsp;
              <a href="/legal#terms-of-service">
                https://triniti.ai/legal#terms-of-service
              </a>
              ), which also includes the provisions in this Privacy Policy. In
              the
              <br /> event of a conflict between the Terms of Use and this
              Privacy Policy,
              <br /> the provisions in this Privacy Policy will prevail
            </p>
          </div>
          <h4>2. PROCESSING PERSONAL DATA</h4>
          <div className="legal-page-margin">
            <p>
              2.1 We process the personal data of individuals whom you have
              supplied
              <br /> to us, including End Users of the Services.
            </p>
            <p>
              2.2 We will only process personal data where we have a lawful
              basis to
              <br /> do so under the GDPR. Lawful bases include instances where
            </p>
            <div className="legal-page-margin">
              <p>
                (i) you have given express and documented consent;
                <br /> (ii) processing is required for the performance of a
                contract with
                <br /> you; and/or
                <br /> (iii) it appears to us that there are legitimate
                interests to
                <br /> process your personal data or the data of your End Users,
                including
                <br /> internal business purposes.
              </p>
            </div>
            <p>
              2.3 The types of personal data processed may include, without
              <br /> limitation, the following information:
            </p>
            <div className="legal-page-margin">
              <p>
                (i) name;
                <br /> (ii) email address;
                <br /> (iii) telephone numbers;
                <br /> (iv) date of birth;
                <br /> (v) usernames and passwords used on the Website;
                <br /> (vi) information associated with the third-party account
                credentials
                <br /> that you use to log in to our Services;
                <br /> (vii) information on how you use the Services, which may
                include
                <br /> your conversation history, IP address, geolocation data,
                device
                <br /> identification, browser data, and information on your
                usage of the
                <br /> Website; and
                <br /> (viii) any other form of personal data relevant to the
                use of the
                <br /> Website and the Services provided by you and/or your End
                Users in
                <br /> connection with the ues of the Website and/or the
                Services, which
                <br /> may directly or indirectly identify you and/or your End
                Users.
                <br /> (ix) Business information
                <br /> (x) Payment information
              </p>
            </div>
            <p>
              2.4 We do not knowingly collect (and neither you nor your End
              Users
              <br /> shall submit or upload) any special categories of data (as
              defined under
              <br /> Article 9(1) of the GDPR).
            </p>
            <p>
              2.5 To provide our Services to you, we use cookies and similar
              <br /> technologies (e.g., web beacons, pixels, ad tags and device
              identifiers)
              <br /> to recognise you and/or your device(s) on, off and across
              different
              <br /> Services and devices. A cookie is a small amount of data
              that our web
              <br /> server sends to your web browser when you visit certain
              parts of our
              <br /> Website and the use of which is intended to assist us in
              providing
              <br /> better services to you on the Website (e.g. by
              understanding and saving
              <br /> your preferences for future visits). We reserve the right
              to refuse
              <br /> access to users who erase or block cookies from this site.
              In some
              <br /> cases, we may automatically (i.e. not via your
              registration) collect
              <br /> technical information that is not personally identifiable
              when you
              <br /> connect to our Website. Examples of this type of
              information include the
              <br /> type of internet browser used, the type of computer
              operating system
              <br /> used and the domain name of the site from which the link to
              this site is
              <br /> activated.
            </p>
            <p>
              2.6 When we process personal data in the course of providing the
              <br /> Services to you, we will assist you, taking into account
              the nature of
              <br /> the processing:
            </p>
            <div className="legal-page-margin">
              <p>
                2.6.1 by appropriate technical and organisational measures and
                where
                <br /> possible, in fulfilling your obligations under the GDPR
                to respond
                <br /> to the requests from data subjects exercising their
                rights;
              </p>
              <p>
                2.6.2 in ensuring compliance with the obligations pursuant to
                <br /> Articles 32 to 36 of the GDPR, taking into account the
                information
                <br /> made available to us;
              </p>
              <p>
                2.6.3 by making available to you information reasonably
                requested by
                <br /> you for the purpose of demonstrating your obligations
                relating to
                <br /> the appointment of data processors as set out in Article
                28 of the
                <br /> GDPR have been met; and
              </p>
              <p>
                2.6.4 by notifying you promptly if, in our opinion, an
                instruction
                <br /> given by you for the processing of personal data
                infringes the GDPR.
              </p>
            </div>
            <p>
              2.7 Where required by the GDPR, we will allow you and your
              authorised
              <br /> representatives to conduct audits (including inspections)
              during the
              <br /> term of the Terms and this Privacy Policy, to ensure
              compliance with the
              <br /> provisions therein. Notwithstanding the foregoing, such
              audit:
            </p>
            <div className="legal-page-margin">
              <p>2.7.1 must be:</p>
              <div className="legal-page-margin">
                <p>
                  (a) conducted during our regular business hours;
                  <br /> (b) with reasonable advance notice to us; and
                  <br /> (c) subject to reasonable confidentiality procedures.
                </p>
              </div>
              <p>
                2.7.2 shall not require us to disclose to you or your authorised
                <br /> representatives, or to allow you or your authorised
                representatives
                <br /> to access:
              </p>
              <div className="legal-page-margin">
                <p>
                  (a) any data or information of any other customer;
                  <br /> (b) any of our internal accounting, financial
                  information or
                  <br /> trade secret;
                  <br /> (c) any information that, in our reasonable opinion
                  could:
                </p>
                <div className="legal-page-margin">
                  <p>
                    (i) compromise the security of our systems, the Website, or
                    <br /> our premises;
                    <br /> (ii) cause us to breach our obligations under the
                    GDPR or
                    <br /> any applicable data protection legislation anywhere
                    in the
                    <br /> world;
                    <br /> (iii) cause us to breach our security,
                    confidentiality
                    <br /> and/or privacy obligations to any other customer or
                    third
                    <br /> party; or
                  </p>
                </div>
                <p>
                  (d) any information that you or your authorised
                  representatives
                  <br /> seek to access for any reason other than the good faith
                  <br /> fulfilment of your obligations under the GDPR and our
                  compliance
                  <br /> with the terms of this Privacy Policy.
                </p>
              </div>
            </div>
            <p>
              2.8 This Privacy Policy does not cover personal information you
              provide
              <br /> to our clients when using products created with the use of
              our Services
              <br /> that are located on our clients’ websites and portals, as
              we do not
              <br /> control the means and purposes of such personal data
              processing as
              <br /> defined in Article 4(7) of the GDPR.
            </p>
          </div>
          <h4>3. PURPOSE OF PROCESSING DATA</h4>
          <div className="legal-page-margin">
            <p>3.1 Access to Services</p>
            <div className="legal-page-margin">
              <p>
                3.1.1 We may use the data we process to deliver the Services to
                you,
                <br /> including to process transactions, authenticate users and
                provide
                <br /> customer support.
              </p>
              <p>
                3.1.2 By providing personal data to us, you hereby consent to
                allow
                <br /> us to use the personal data provided for the purposes of
                providing
                <br /> the Services to you, including monitoring your access to
                and use of
                <br /> the Website and its various services.
              </p>
            </div>
            <p>3.2 Improvement of Services</p>
            <div className="legal-page-margin">
              <p>
                3.2.1 We use the data we process to improve the Services offered
                to
                <br /> you, including for algorithm training purposes and other
                analytics.
                <br /> We use collective learnings about how users and End Users
                use our
                <br /> Services and feedback provided directly to us to
                troubleshoot and to
                <br /> identify trends, usage, activity patterns and areas for
                integration
                <br /> and improvement of the Services.
              </p>
            </div>
            <p>3.3 Communication</p>
            <div className="legal-page-margin">
              <p>
                3.3.1 We may contact you through email, telephone, facsimile,
                <br /> notices posted on the Website, and other ways through our
                Services,
                <br /> for example, to send you messages about the availability
                of our
                <br /> Services, security, or other service-related issues. We
                may also
                <br /> send you messages about how to use the Services, and
                other
                <br /> promotional messages from us and our partners. Please be
                aware that
                <br /> you cannot opt-out of receiving service messages from us,
                including
                <br /> security and legal notices.
              </p>
            </div>
            <p>3.4 Marketing and Advertisement</p>
            <div className="legal-page-margin">
              <p>
                3.4.1 We may use, target, and measure the performance of our
                <br /> Services using the following data, whether separately or
                combined:
              </p>
              <div className="legal-page-margin">
                <p>
                  (a) Data from advertising technologies on and off our
                  Services,
                  <br /> like web beacons, pixels, ad tags, cookies, and device
                  <br /> identifiers;
                  <br /> (b) User-provided information (e.g., profile, contact
                  <br /> information, title and industry);
                  <br /> (c) Data from your use of our Services (e.g., page
                  visits from
                  <br /> End Users, algorithm analysis, etc.);
                  <br /> (d) Information from advertising partners and
                  publishers; and
                  <br /> (e) Information inferred from data described above.
                </p>
              </div>
              <p>
                3.4.2 By providing your personal data to us and using the
                Services,
                <br /> you expressly provide consent to us and our affiliates to
                contact
                <br /> you by telephone, facsimile and e-mail, and to transfer
                your
                <br /> information to our affiliates for the purpose of updating
                and
                <br /> enhancing our records. Your provision of personal
                information to us
                <br /> also constitutes an express consent for us and/or our
                affiliates to
                <br /> contact you and to advise you of events, products and
                services which
                <br /> it thinks may be of interest to you.
              </p>
            </div>
          </div>
          <h4>4. TRANSFER OF DATA</h4>
          <div className="legal-page-margin">
            <p>
              4.1 We do not give access to, rent sell, trade, or otherwise
              transfer to
              <br /> third parties personal data for such third party’s
              independent use,
              <br /> except as provided in this Privacy Policy, or with your
              prior written
              <br /> consent.
            </p>
            <p>4.2 We will ensure that:</p>
            <div className="legal-page-margin">
              <p>
                4.2.1 our personnel, agents and advisors required to access
                personal
                <br /> data are subject to a contractual or statutory binding
                duty with
                <br /> regard to such personal data to keep your personal
                information
                <br /> secure from unauthorised access, collection, use,
                disclosure, or
                <br /> similar risks and retain your personal information only
                for as long
                <br /> as they need your personal information to achieve the
                stated
                <br /> purposes; and
              </p>
              <p>
                4.2.2 none of our personnel, agents and advisors publish,
                disclose
                <br /> or divulge any personal data to any third party, except
                as stated in
                <br /> this Clause 4 or in accordance with prior written
                instructions or
                <br /> consent from you;
              </p>
            </div>
            <p>4.3 Affiliates and third party sub-processors</p>
            <div className="legal-page-margin">
              <p>
                4.3.1 You consent to our appointment of affiliates and
                applicable
                <br /> third party sub-processors for the purposes described in
                this
                <br /> Privacy Policy, including our website hosting partners
                and other
                <br /> parties who assist us in operating the Website,
                conducting our
                <br /> business, or providing the Services to you, so long as
                those parties
                <br /> agree to keep this information confidential. We may
                update the third
                <br /> party sub-processors that we use, and will inform you by
                email. In
                <br /> the event that there are any changes, you will have the
                opportunity
                <br /> to object to such changes within 7 days of such changes
                being made.
              </p>
              <p>
                4.3.2 We may share your personal data with our affiliates to
                provide
                <br /> and develop our Services. We may combine information
                internally
                <br /> across the different Services covered by this Privacy
                Policy to help
                <br /> our Services be more relevant and useful to you and
                others.
              </p>
              <p>
                4.3.3 We may use third party sub-processors to help us provide
                our
                <br /> Services (e.g., maintenance, analysis, audit, payments,
                fraud
                <br /> detection, marketing and development). They will have
                access to your
                <br /> information as reasonably necessary to perform these
                tasks on our
                <br /> behalf and are contractually obligated not to disclose or
                use it for
                <br /> other purposes.
              </p>
              <p>
                4.3.4 In engaging such third party sub-processors, we will
                ensure
                <br /> that we are responsible for the performance of each
                sub-processor,
                <br /> and we will include in our agreement with any such third
                party
                <br /> sub-processor terms that are at least as favourable to
                you as those
                <br /> contained herein and/or as are required by the GDPR.
              </p>
            </div>
            <p>4.4 Legal Disclosures</p>
            <div className="legal-page-margin">
              <p>
                4.4.1 It is possible that we will need to access, ready,
                preserve
                <br /> and/or disclose information about you when required by
                law,
                <br /> subpoena, or other legal process or if we have a good
                faith belief
                <br /> that disclosure is reasonably necessary to:
              </p>
              <div className="legal-page-margin">
                <p>
                  (a) investigate, prevent, or take action regarding suspected
                  or
                  <br /> actual illegal activities or to assist government
                  enforcement
                  <br /> agencies;
                  <br /> (b) enforce or apply our Terms of Use and other
                  agreements with
                  <br /> you;
                  <br /> (c) investigate and defend ourselves against any
                  third-party
                  <br /> claims or allegations;
                  <br /> (d) protect the security or integrity of our Services
                  (such as
                  <br /> by sharing with companies facing similar threats); or
                  <br /> (e) exercise or protect the rights, property or safety
                  of the
                  <br /> Website, the provision of the Services, our registered
                  users,
                  <br /> employees, personnel, or others.
                </p>
              </div>
              <p>
                4.4.2 We may attempt to notify users about legal demands for
                their
                <br /> personal data when appropriate in our judgment, unless
                prohibited by
                <br /> law or court order or when the request is an emergency.
                We may
                <br /> dispute such demands when we believe, in our discretion,
                that the
                <br /> requests are overbroad, vague or lack proper authority,
                but we do
                <br /> not promise to challenge every demand.
              </p>
              <p>
                4.4.3 We may also share your personal data as part of a sale,
                merger
                <br /> or change in control, or in preparation for any of these
                events. Any
                <br /> other entity which buys us or part of our business will
                have the
                <br /> right to continue to use your data, but only in the
                manner set out
                <br /> in this Privacy Policy (as may be amended from time to
                time) unless
                <br /> you agree otherwise.
              </p>
            </div>
            <p>4.5 International data transfers</p>
            <div className="legal-page-margin">
              <p>
                4.5.1 If any disclosure of your information involves the
                transfer of
                <br /> your personal information out of Singapore and/or the
                EEA, we will
                <br /> take steps to ensure that the receiving jurisdiction has
                in place a<br /> standard of protection accorded to personal
                information that is
                <br /> comparable to the protection under or up to Singapore’s
                data
                <br /> protection legislation.
              </p>
            </div>
          </div>
          <h4>5. ACCESS AND CONTROL OF DATA</h4>
          <div className="legal-page-margin">
            <p>
              5.1 We offer settings to control and manage some aspects of the
              personal
              <br /> data that we have about you. You may update any of your
              personal
              <br /> information we possess by means of updating and accessing
              your account
              <br /> details, and if not, then by contacting us.
            </p>
          </div>
          <h4>6. RETENTION OF DATA</h4>
          <div className="legal-page-margin">
            <p>
              6.1 We retain personal data that we process while your account is
              in
              <br /> existence or as needed to provide you with the Services.
              This includes
              <br /> data you or others provide to us, and data generated or
              inferred from
              <br /> your use of our Services.
            </p>
            <p>
              6.2 If you choose to close your account and terminate your access
              to the
              <br /> Services, we will delete the personal data of you and/or
              your End Users
              <br /> within 60 days of the account closure, except as noted
              below:
            </p>
            <div className="legal-page-margin">
              <p>
                6.2.1 we may retain your personal data even after you have
                closed
                <br /> your account if reasonably necessary to comply with our
                legal
                <br /> obligations (including law enforcement requests), meet
                regulatory
                <br /> requirements, resolve disputes, maintain security,
                prevent fraud and
                <br /> abuse, enforce our Terms of Use, or fulfil your request
                to
                <br /> “unsubscribe” from further messages from us; and
              </p>
              <p>
                6.2.2 we may choose to retain any of your current or deleted
                data in
                <br /> anonymised or aggregated form.
              </p>
            </div>
          </div>
          <h4>7. SECURITY</h4>
          <div className="legal-page-margin">
            <p>
              7.1 In accordance with Article 32 of the GDPR, we have implemented
              and
              <br /> will maintain reasonable security arrangements including
              physical,
              <br /> administrative, technical, and electronic security measures
              to protect
              <br /> against the loss, misuse, damage, theft, destruction or
              alteration of
              <br /> your personal information. Despite our best efforts,
              however, no
              <br /> security measures are perfect or impenetrable. You are
              advised that,
              <br /> should you or your End User choose to provide information
              or data to us
              <br /> and/or through the Website or our Services, the security of
              any
              <br /> information transmitted over the Internet cannot be
              guaranteed.
            </p>
            <p>
              7.2 It is your responsibility to protect any passwords you require
              to
              <br /> access your account, the Website or the Services. Please
              use unique
              <br /> numbers, letters and special characters, and do not share
              your password
              <br /> with anyone. If you do share your password with others, you
              will be
              <br /> responsible for all actions taken in the name of your
              account and the
              <br /> consequences. If you lose control of your password, you may
              lose
              <br /> substantial control over your personal information and
              other information
              <br /> submitted to us. You could also be subject to legally
              binding actions
              <br /> taken on your behalf. Therefore, if your password has been
              compromised
              <br /> for any reason or if you have grounds to believe that your
              password has
              <br /> been compromised, you should immediately contact us and
              change your
              <br /> password.
            </p>
            <p>
              7.3 You undertake to treat your password and other confidential
              <br /> information in relation to the use of the Website and the
              Services
              <br /> confidentially, and, to the furthest extent permissible by
              law, we
              <br /> expressly disclaim any liability arising from your failure
              to do so.
            </p>
            <p>
              7.4 In the event where you believe any accidental, unauthorised or
              <br /> unlawful destruction, loss, alteration, or disclosure of,
              or access to
              <br /> your personal data that we process in the course of
              providing the
              <br /> Services (a “Security Breach”) has occurred, please contact
              us
              <br /> immediately. If we become aware of and confirm any Security
              Breach, we
              <br /> will notify you without undue delay.
            </p>
          </div>
          <h4>8. WITHDRAWAL OF CONSENT</h4>
          <div className="legal-page-margin">
            <p>
              8.1 You may communicate your objection to our continual use,
              disclosure
              <br /> and/or retention of your personal information for any of
              the purposes
              <br /> and in the manner as stated above at any time by contacting
              us at the
              <br /> following email address:
            </p>
            <div className="legal-page-margin">
              <p>Email: support@triniti.ai</p>
            </div>
            <p>
              8.2 Please note that if you communicate your objection to our use,
              <br /> disclosure and/or retention of your personal information
              for the
              <br /> purposes and in the manner as stated above, depending on
              the nature of
              <br /> your objection, we may not be in a position to continue to
              provide the
              <br /> Services to you or perform on any contract we have with
              you. Our legal
              <br /> rights and remedies are expressly reserved in such event.
            </p>
          </div>
          <h4>9. GENERAL</h4>
          <div className="legal-page-margin">
            <p>
              9.1 We may amend this Privacy Policy at any time by posting the
              amended
              <br /> terms on the Website, and these amendments will become
              effective upon
              <br /> posting. Your continued use of the Website and the Services
              following
              <br /> these changes means that you accept such revisions. If we
              make any
              <br /> material changes to this Privacy Policy, we will try to
              provide at least
              <br /> 30 days notice prior to any new terms taking effect. What
              constitutes a<br /> material change will be determined at our sole
              discretion.
            </p>
            <p>
              9.2 You acknowledge that your continued use of our Services after
              we
              <br /> publish or send a notice about our changes to this Privacy
              Policy means
              <br /> that the collection, use and sharing of your personal data
              is subject to
              <br /> the updated Privacy Policy.
            </p>
            <p>
              9.3 You acknowledge that, notwithstanding this Privacy Policy, we
              have
              <br /> at all times the right to disclose your personal
              information to any
              <br /> legal, regulatory, governmental, tax, law enforcement or
              other
              <br /> authorities pursuant to applicable law and our legal
              obligations. This
              <br /> may arise from any investigation, order, or request by such
              parties. To
              <br /> the furthest extent permissible by law, you agree not to
              take any action
              <br /> and/or waive your rights to take any action against us for
              the
              <br /> disclosure of your personal information in these
              circumstances.
            </p>
            <p>
              9.4 If you wish to contact us for anything in relation to this
              Privacy
              <br /> Policy, please feel free to contact us (and our data
              protection officer)
              <br /> by writing to:
            </p>
            <div className="legal-page-margin">
              <p>Email: dpo@triniti.ai</p>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default PrivacyPolicy;
